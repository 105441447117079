import React from 'react';
import { formatBytes } from '../../common/utils';
import Modal from '../../common/modal';

const PlanDetail = ({ userInfo }) => {
    return (
        <Modal target={"planDetail"} title={"套餐详情"}>
            {userInfo.plan_detail ? (
                userInfo.plan_detail.id === 0 ? (
                    <div className="text-center p-4 ">
                        <div className="text-xl font-semibold text-red-800">套餐已下架</div>
                        <div className="mt-2 text-sm text-gray-600">
                            该套餐已停止提供，您可以选购其他可用套餐。
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="mb-4 flex justify-between">
                            <div className="text-lg font-medium text-gray-900">套餐名称</div>
                            <div className="text-sm text-gray-500">{userInfo.plan_detail.name}</div>
                        </div>
                        <div className="mb-4 flex justify-between">
                            <div className="text-lg font-medium text-gray-900">套餐流量</div>
                            <div className="text-sm text-gray-500">{formatBytes(userInfo.plan_detail.traffic)}</div>
                        </div>
                        <div className="mb-4 flex justify-between">
                            <div className="text-lg font-medium text-gray-900">套餐价格</div>
                            <div className="text-sm text-gray-500">{userInfo.plan_detail.price}￥</div>
                        </div>
                        <div className="mb-4 flex justify-between">
                            <div className="text-lg font-medium text-gray-900">套餐时长</div>
                            <div className="text-sm text-gray-500">{userInfo.plan_detail.days === 0 ? "不限时长" : userInfo.plan_detail.days + "天"}</div>
                        </div>
                    </>
                )
            ) : (
                <div className="text-sm text-gray-500">无法获取套餐详情</div>
            )}
        </Modal>
    );
}

export default PlanDetail;
