import React from "react";
import Modal from '../../common/modal';

const Tutorial = ({ subLink }) => {
    return (
        <Modal target={"android"} title={"使用教程"}>
            <main className="py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <section className="mb-12">
                        <p className="text-xl text-gray-700 mb-12 text-center">
                            按照以下步骤在 Android 上设置并使用。
                        </p>
                        <div className="grid grid-cols-1 gap-8 mb-12">
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 1: 下载</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    下载并安装 Clash Meta
                                </p>
                                <a href="https://clash.run/d/cmfa-2.10.3-meta-universal-release.apk" target="_blank" className="text-blue-500 hover:underline">
                                    下载链接
                                </a>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 2: 安装</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    打开下载的安装程序，按照提示安装 Clash Meta
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 3: 导入</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    <button
                                        onClick={() => {
                                            let encodeurl = encodeURIComponent(subLink);
                                            window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                                        }}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        一键导入节点
                                    </button>
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 4: 运行</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    导入后在配置中选中，然后启动服务即可
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Modal>
    );
};

export default Tutorial;