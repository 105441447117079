import React from "react";
const Plan = ({ title, price, features, onSelect }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
        <h3 className="text-center text-2xl font-semibold text-gray-900">
          {title}
        </h3>
        <div className="mt-4 flex items-center justify-center">
          <span className="text-6xl font-extrabold text-gray-900">{price}</span>
        </div>
        <ul className="mt-6 space-y-4">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <p className="ml-3 text-base text-gray-700">{feature}</p>
            </li>
          ))}
        </ul>
      </div>
      <div onClick={onSelect} className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
        <button
          className="btn w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          订阅{title}
        </button>
      </div>
    </div>
  );
};
export default Plan;
