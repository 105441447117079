import React, { useState, useEffect } from "react";
import api from "../common/api";
import { formatBytes, convertTimestamp, changeModal, clear } from "../common/utils";
import Modal from 'react-modal';
import toast from "react-hot-toast";
import Tips from "../common/tips";
import Switch from 'react-switch';

import ChangeEmail from './func/ChangeEmail'; // 修改邮箱
import Recharge from './func/Recharge'; // 引入充值组件
import Sign from './func/Sign'; // 引入签到组件
import Recom from './func/Recom'; // 引入邀请组件
import Passwd from "./func/Passwd"; // 引入修改密码组件
import PlanDetail from "./func/PlanDetail";
import RegTips from "./func/Tips";
import Sub from "./func/Sub";
import ChangeSub from "./func/ChangeSub";
import RePlan from "./func/rePlan";

function Dashboard() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);


  const [userInfo, setUserInfo] = useState({});
  const [subLink, setSubLink] = useState({});
  const [nodeList, setNodeList] = useState([]);
  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(userInfo.autoRenew);
  const handleAutoRenewChange = async (checked) => {
    const resp = await api("post", "/api/user/renew", null);
    if (resp.status) {
      setIsAutoRenewEnabled(checked);
      // 更新本地缓存
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      storedUserInfo.renew = checked ? 1 : 0;
      localStorage.setItem('userInfo', JSON.stringify(storedUserInfo));
    } else {
      toast.error("自动续期设置失败");
    }
  };
  const getUserInfo = () => {
    toast.promise(
      api("post", "/api/user/info", null),
      {
        loading: "正在加载会员信息",
        success: (response) => {
          if (response.status) {
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            // 设置三分钟过期
            localStorage.setItem('userInfo_expire', Date.now() + 180000);
            setUserInfo(response.data);
            setSubLink(process.env.REACT_APP_LINK + `/sub?token=${response.data.token}`);
            setIsAutoRenewEnabled(response.data.renew === 1);
            getNodes();
            return "加载成功";
          } else {
            if (response.message === "401") {
              clear();
              window.location.href = '/login';
              throw new Error("请重新登录");
            } else {
              throw new Error(response.message);
            }
          }
        },
        error: (error) => {
          return error.message;
        }
      }
    );
  };

  const getNodes = async () => {
    if (localStorage.getItem('node') === null || localStorage.getItem('node_expire') < Date.now()) {
      try {
        const response = await api("get", "/api/user/node", null);
        setNodeList(response.data);
        localStorage.setItem('node', JSON.stringify(response.data));
        // 设置两小时过期
        localStorage.setItem('node_expire', Date.now() + 7200000);
      } catch (error) {
        console.error(error);
      }
    } else {
      setNodeList(JSON.parse(localStorage.getItem('node')));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("register")) {
      const tipsCheckbox = document.getElementById('tips');
      if (tipsCheckbox) {
        tipsCheckbox.checked = true;
      }
    }
    if (params.get('do') === 'charge') {
      changeModal('recharge', true);
    }
    if (params.get('do') === 'sub') {
      changeModal('sub', true);
    }
    if (localStorage.getItem('userInfo') && localStorage.getItem('userInfo_expire') > Date.now()) {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      setUserInfo(storedUserInfo);
      setIsAutoRenewEnabled(storedUserInfo.renew === 1);
      setSubLink(process.env.REACT_APP_LINK + `/sub?token=${storedUserInfo.token}`);
      getNodes();
    } else {
      getUserInfo();
    }
  }, []);

  Modal.setAppElement('#root')
  return (
    <>
      <Tips msg="加入TG群吹水领福利" title="加入群聊" link={`https://t.me/${process.env.REACT_APP_TG_GROUP}`} />
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-3xl mx-auto">
          <div className="stats shadow w-full">
            <div className="stat">
              <div className="stat-figure text-primary hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block h-8 w-8 stroke-current">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                </svg>
              </div>
              <div className="stat-title">余额</div>
              <div className="stat-value text-primary">{userInfo.credit ? Math.floor(userInfo.credit) : 0}</div>
              <div className="stat-actions">
                <label htmlFor="recharge" className="btn btn-sm">充值</label>
              </div>
            </div>
            <div className="stat">
              <div className="stat-figure text-secondary hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block h-8 w-8 stroke-current">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                </svg>
              </div>
              <div className="stat-title">奖励</div>
              <div className="stat-value text-secondary">{userInfo.aff ? Math.floor(userInfo.aff) : 0}</div>
              <div className="stat-actions">
                <label htmlFor="recom" className="btn btn-sm">邀请好友</label>
              </div>
            </div>
          </div>
          <div className="navbar bg-base-100 my-4 rounded-lg shadow">
            <div className="flex-1">
              <a className="btn btn-ghost text-xl">{userInfo.username}</a>
            </div>
            <div className="flex-none">
              <ul className="menu menu-horizontal px-1 font-semibold">
                <li><label htmlFor="sign" >签到</label></li>
                <li>
                  <details>
                    <summary>修改资料</summary>
                    <ul className="bg-base-100 rounded-t-none p-2">
                      <li><label htmlFor="passwd" >修改密码</label></li>
                      <li><label htmlFor="changeEmail" >修改邮箱</label></li>
                    </ul>
                  </details>
                </li>
              </ul>
            </div>
          </div>
          <ChangeEmail
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
          <Sign
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            getUserInfo={getUserInfo}
          />
          <Recom
            userInfo={userInfo}
            getUserInfo={getUserInfo}
          />
          <Passwd />
          <RegTips />
          <Recharge getUserInfo={getUserInfo}
          />
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <RePlan
              userInfo={userInfo}
              getUserInfo={getUserInfo}
            />
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">订阅信息</h2>
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  {userInfo.able === 1 ? (
                    <>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">总流量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{formatBytes(userInfo.traffic)}</div>
                          <a href="/plan" className="font-semibold text-indigo-600 hover:text-indigo-500">
                            {userInfo.able === 0 ? '购买套餐' : '更换套餐'}
                          </a>
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">已用流量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{formatBytes(userInfo.trafficked)}</div>
                          {userInfo.plan !== 0 &&
                            (
                              <label htmlFor="rePlan" className="font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer">重置套餐</label>
                            )}
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">速率限制</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">
                            {userInfo.plan === 0 && userInfo.traffic < 50 * 5368709120 ? "50Mbps" : (userInfo.plan.id !== 0 && userInfo.plan_detail.limit === 0 ? '不限速' : `${userInfo.plan_detail.limit}Mbps`)}
                          </div>
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">设备数量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">不限制</div>
                        </dd>
                      </div>
                      {userInfo.plan !== 0 && userInfo.expired !== 0 && (
                        <div className="pt-6 sm:flex">
                          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">自动续费</dt>
                          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <Switch
                              onChange={handleAutoRenewChange}
                              checked={isAutoRenewEnabled}
                              offColor="#f56565"
                              onColor="#48bb78"
                            />
                          </dd>
                        </div>
                      )}
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">到期时间</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{convertTimestamp(userInfo.expired)}</div>
                        </dd>
                      </div>
                      <div className="bg-gradient-to-r bg-gray-200 h-2 rounded-lg mb-2">
                        <div
                          className="bg-indigo-600 h-full rounded-lg"
                          style={{
                            width: `${((userInfo.traffic - userInfo.trafficked) /
                              userInfo.traffic) *
                              100
                              }%`,
                          }}
                        />
                      </div>
                      <div className="justify-between flex">
                        <div className="join join-vertical sm:join-horizontal mt-2">
                          <label htmlFor="sub" className="btn join-item">订阅 [导入节点]</label>
                          <label htmlFor="changeSub" className="btn join-item">修改订阅地址</label>
                        </div>
                        <div className="join join-vertical sm:join-horizontal mt-2">
                          {userInfo.plan !== 0 && (
                            <>
                              <label htmlFor="planDetail" className="btn join-item">查看套餐详情</label>
                              <PlanDetail userInfo={userInfo} />
                            </>
                          )}
                          <button onClick={() => window.location.href = '/help'} className="btn join-item ">查看使用教程</button>
                        </div>
                      </div>
                      <Sub
                        subLink={subLink}
                      />
                      <ChangeSub getUserInfo={getUserInfo} />
                    </>
                  ) : (
                    <div className="text-center">
                      {userInfo.trafficked >= userInfo.traffic ? (
                        <>
                          <h3 className="mt-8 mb-1 text-lg font-semibold text-gray-900">流量耗尽</h3>
                          <p className="text-sm text-gray-600"><span className="text-red-600">{formatBytes(userInfo.trafficked)}</span>/{formatBytes(userInfo.traffic)}</p>                        </>
                      ) : userInfo.expired <= Date.now() && userInfo.expired !== 0 ? (
                        <>
                          <h3 className="mt-8 text-lg font-semibold text-gray-900">套餐过期</h3>
                          <p className="text-sm text-gray-600">{convertTimestamp(userInfo.expired)}</p>
                        </>
                      ) : (
                        <>
                          <h3 className="mt-8 text-lg font-semibold text-gray-900">套餐有效</h3>
                          <p className="text-sm text-gray-600">剩余流量：{formatBytes(userInfo.traffic - userInfo.trafficked)}</p>
                        </>
                      )}
                      {userInfo.expired === 0 && userInfo.trafficked < userInfo.traffic ? (
                        <p className="mt-2 text-sm text-gray-600">请签到或购买套餐以解锁服务。</p>
                      ) : (
                        <p className="mt-2 text-sm text-gray-600">请签到或购买套餐以使用服务。</p>
                      )}
                      <a href="/plan" className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        购买套餐
                      </a>
                      {userInfo.trafficked >= userInfo.traffic && userInfo.plan !== 0 &&
                        (
                          <>
                            <label htmlFor="rePlan" className="m-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer">重置套餐</label>
                            <br />
                            <label htmlFor="planDetail" className="btn-sm bg-gray-500 hover:bg-gray-700 text-white font-bold mx-2 py-2 px-4 rounded cursor-pointer">查看套餐详情</label>
                            <PlanDetail userInfo={userInfo}
                            />
                          </>
                        )}
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <h2 className="text-2xl font-semibold mb-4">可用节点</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {nodeList && nodeList
              .sort((a, b) => a.rate - b.rate)
              .map((node, index) => (
                <div key={index} className="bg-white shadow-md rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-blue-500"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform: 'translateY(-15%)' }}
                        >
                          <path d="M3.5 13a4.5 4.5 0 118.94-2.03A4 4 0 0114 18H4a3.5 3.5 0 01-.5-7z" />
                        </svg>
                      </div>
                      <div className="ml-4">
                        <div className="text-lg font-medium text-gray-900 truncate max-w-xs">{node.name.replace('中转', '')}</div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">{node.rate}x</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div >
    </>
  );
}

export default Dashboard;